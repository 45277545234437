import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 16",
  week: "Semana 3",
  month: "Mar",
  day: "29",
  monthNumber: "03",
  date: "2020-03-29",
  path: "/cronologia/semana-03#dia-29-mar",
};

const Day16 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 6.549 nuevos positivos y 838 personas fallecidas.
          Permanecen hospitalizadas 43.397 personas, 2.749 más que el día
          anterior, y 2.324 reciben el alta. El número de casos ingresados en la
          UCI asciende a 4.907, lo que supone un aumento de 332 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 8,3 % y la tasa de recuperados del 16,8 %.
        </ModText>
        <ModTwoCols
          src="/images/svg/29_mar_mayores.svg"
          alt="personas mayores con necesidad de atención"
          small={false}
          inverted={false}
        >
          Según los datos obtenidos hasta la fecha, el mayor número de casos
          confirmados se concentra en personas con 50-59 años, pero son las
          edades comprendidas entre 70-79 años las que necesitan más atención
          hospitalaria. En cuanto al número de personas fallecidas, el
          porcentaje más elevado lo encontramos en personas con más de 80 años.
        </ModTwoCols>
        <ModText>
          El Ministerio de Sanidad amplía a{" "}
          <strong>5 las CCAA con necesidad de refuerzo en UCI</strong>: Madrid,
          Cataluña, País Vasco, La Rioja y Navarra presentan un nivel de
          saturación hospitalario muy elevado.
        </ModText>
        <ModText>
          Esta información queda reforzada desde que el pasado 21 de marzo el
          ministerio empezase a reportar informes del número de casos que
          necesitaban hospitalización:
        </ModText>
        <ModGraph
          name="29-03-saturacion-hospitalaria"
          alt="mapa de saturación hospitalaria por covid en las CC.AA. Españolas"
        />
        <ModText>
          Teniendo en cuenta la capacidad operativa de cada comunidad autónoma y
          el número de camas ocupadas por enfermos de coronavirus, podemos
          detectar en qué regiones existe la necesidad de recursos sanitarios.
        </ModText>
        <ModText>
          <strong>
            {" "}
            El Gobierno aprueba un permiso retribuido recuperable{" "}
          </strong>
          para las personas trabajadoras de servicios no esenciales. Disfrutarán
          de ese permiso, de carácter obligatorio, del 30 de marzo al 9 de abril
          de 2020, ambos inclusive. Dicho permiso no se aplicará a personas que
          se encuentren ya trabajando a distancia, trabajadores en ERTE o
          sujetos a un procedimiento de incapacidad laboral o con baja por
          maternidad y paternidad.
        </ModText>
        <ModText>
          También ha hecho público un{" "}
          <strong>
            informe en el que detalla las principales actuaciones llevadas a
            cabo
          </strong>{" "}
          para apoyar a las comunidades y ciudades autónomas en su lucha contra
          el coronavirus. El impacto estimado de las principales medidas
          aprobadas es de 125.104 millones de euros, de los cuales, 3.945 se
          destinan a medidas sanitarias, 116.235 a medidas económicas, 4.299 a
          medidas laborales y 625 a medidas sociales.
        </ModText>
        <ModAnimation svg="/images/anim/23_mar_fuerzas_armadas.svg" />
        <ModText>
          <strong>
            {" "}
            Un avión del Ejército del Aire español aterriza en Torrejón con
            10.000 equipos de protección individual
          </strong>{" "}
          procedentes de la República Checa. El envío responde a la petición que
          formuló el Gobierno al Centro Euroatlántico de Coordinación de
          Respuestas a Desastres de la OTAN para que los aliados suministraran
          material sanitario con el que hacer frente al Covid-19.
        </ModText>
        <ModText>
          El Ministerio de Asuntos Exteriores, Unión Europea y Cooperación ha
          comenzado a habilitar algunas de sus instalaciones para acoger a
          aquellos turistas españoles para los que las Embajadas y Consulados de
          España buscan alternativas con las que facilitar su retorno.
        </ModText>
        <ModReferenceList title="Guías y documentos publicados">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/presidencia/Documents/2020/29032020_ActuacionesCOVID-19.pdf"
            name="Principales actuaciones para apoyar a comunidades y ciudades autonómicas"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day16;
